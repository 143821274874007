<template>

  <div>

    <div class="text-center mb-5">
      <h5>Usuarios del cliente </h5>
      <span class="text-secondary d-block mb-3">Administre los usuarios del cliente aquí.</span>
    </div>

    <div class="row mb-3" >
      <div class="col-sm-auto">
        <button class="btn btn-sm btn-info" @click="cargar_usuarios"><i class='fa fa-refresh'></i> Recargar</button>
      </div>
      <div class="col-sm-auto"  v-if="$auth.can('bpb_apps_admin','usuarios_crear')">
        <button  class="btn btn-sm btn-primary" @click="formulario_usuario"><i class='fa fa-plus-circle'></i> Nuevo</button>
      </div>
    </div>

    <table class="table table-sm table-striped">
      <thead>
      <tr class="bg-dark">
        <th scope="col"></th>
        <th scope="col">Usuario</th>
        <th scope="col">Correo</th>
        <th scope="col">Nombre</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!usuarios.length"><td colspan="4" class="text-secondary text-center"> No se encontraron usuarios para mostrar.</td></tr>
      <tr v-for="item_usuario in usuarios">
        <td class="text-center">
          <button type="button" class="btn btn-sm btn-primary rounded-circle fa fa-edit" @click="formulario_usuario(item_usuario)"></button>
          <button type="button" class="btn btn-sm btn-dark rounded-circle  ml-2" @click="pre_eliminar(item_usuario)"><i class="fa fa-trash"></i></button>
        </td>
        <td>{{item_usuario.usuario}}</td>
        <td>{{item_usuario.email}}</td>
        <td>{{item_usuario.nombre}}</td>
      </tr>
      </tbody>
    </table>

    <Modal v-if="formulario" :options="{width: '50vw',type:'primary'}" @close="cerrar_modal">
      <div class="title"> {{!usuario.id ? 'Nuevo' : 'Editar'}} Usuario</div>

      <div class="body"  v-if="!roles.length">
        <div class="alert alert-warning text-center" role="alert">
          No se encontraron roles de la aplicación, primero deber agregarlos en la sección de módulos o clientes.
        </div>
      </div>

      <div class="body"  v-else>

        <div class="row justify-content-end my-3" v-if="usuario.id">
          <div class="col-sm-auto">
            <button class="btn btn-sm btn-success" @click="modalResetPassword = true"> <i class='fa fa-id-card mr-sm-1'></i>Generar nueva contraseña</button>
          </div>
        </div>

        <div class="row form-group">
          <label for="nombre_usuario" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="usuario.nombre" type="text" name="nombre_usuario" id="nombre_usuario" class="form-control"></div>
        </div>

        <div class="row form-group">
          <label for="email_usuario" class="col-form-label col-sm-2">Correo</label>
          <div class="col-sm-10"><input v-model="usuario.email" type="text" name="email_usuario" id="email_usuario" class="form-control"></div>
        </div>

        <div class="row form-group">
          <label for="sexo_usuario" class="col-form-label col-sm-2">Sexo</label>
          <div class="col-sm-10">
            <select v-model="usuario.sexo" name="sexo_usuario" id="sexo_usuario" class="form-control">
            <option value="femenino">Femenino</option>
            <option value="masculino">Masculino</option>
          </select>
          </div>
        </div>

        <div class="row form-group">
          <label for="rfc_usuario" class="col-form-label col-sm-2">RFC</label>
          <div class="col-sm-10"><input v-model="usuario.rfc" type="text" name="rfc_usuario" id="rfc_usuario" class="form-control" maxlength="13"></div>
        </div>

        <div class="row form-group">
          <label for="curp_usuario" class="col-form-label col-sm-2">CURP</label>
          <div class="col-sm-10"><input v-model="usuario.curp" type="text" name="curp_usuario" id="curp_usuario" class="form-control"  maxlength="18"></div>
        </div>

        <!--

        <div class="row form-group">
          <label for="email_usuario" class="col-form-label col-sm-2">ESTATUS</label>
          <div class="col-sm-10">
            <select v-model="aplicacion.estatus" name="estatus" id="estatus" class="form-control">
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>
        </div>
        -->

        <div class="mb-3 clearfix">
          <h5 class="d-block"> Roles ({{usuario.roles_usuario.length}})</h5>
          <small class="text-secondary">Seleccione los roles de usuario.</small>
          <div class="text-danger" v-if="validacion_seleccion_roles">
            * Debe seleccionar al menos un rol.
          </div>

          <ul id="lista-modulos-form" class="mt-2">
            <li v-for="rol in roles">
              <input class="form-check-input" type="checkbox" :id="'rol-'+rol.id" :value="rol.id" v-model="usuario.roles_usuario">
              {{ rol.nombre }}
            </li>
          </ul>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar" :disabled="!usuario.id && !roles.length"><i class='fa fa-save mr-sm-1'></i>Guardar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalEliminar" :options="{width: '30vw',type:'error'}" @close="cerrar_modal">
      <div class="title">Eliminar usuario</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el usuario: <b>{{ usuario.nombre }}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_usuario"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalResetPassword" :options="{width: '30vw',type:'warning'}" @close="cerrar_modal">
      <div class="title">Generar nueva contraseña a usuario</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Se le generará una nueva contraseña al usuario: <b>{{ usuario.nombre }}</b> y se le notificará por correo. <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="reset_password"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="this.modalResetPassword = false"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

  </div>

</template>

<script>

import Modal from '@/components/Modal'
import apiClientes from '@/apps/bpb_apps_admin/api/clientes.api'
import apiAplicaciones from '@/apps/bpb_apps_admin/api/aplicaciones.api'
import modulos from "@/apps/bpb_apps_admin/api/modulos.api";
import api from "@/apps/bpb_apps_admin/api/paquetes.api";

export default {
  name: "Usuarios",
  components: {Modal},
  data(){
    return {
      usuarios: []
      ,roles: []
      ,formulario: false
      ,modalEliminar: false
      ,modalResetPassword: false
      ,validacion_seleccion_roles: false
      ,usuario: {
        id: null,
        nombre: null,
        sexo: null,
        rfc: null,
        curp: null,
        email: null,
        roles_usuario: []
      }
    }
  },
  props: {
    aplicacion_id: null
    ,cliente_id: null
  },
  computed: {
  },
  mounted() {
    this.cargar_usuarios()
  },
  methods: {

    async cargar_usuarios(){
      let dataSource = await apiClientes.index_usuarios(this.aplicacion_id, this.cliente_id)
      this.usuarios = dataSource.data;
    },

    async formulario_usuario(usuario_editar = null){
      try {
        if (usuario_editar && usuario_editar.id) {
          const usuario = await apiClientes.usuario_cliente(this.aplicacion_id,this.cliente_id,usuario_editar.id)
          this.usuario =  JSON.parse(JSON.stringify(usuario.data))
          this.usuario.roles_usuario =  this.usuario.roles_usuario.map(item => item.rol_id)
        }

        const roles =  await apiAplicaciones.roles(this.aplicacion_id);
        this.roles = roles.data;

        this.formulario = true
      }catch (e) {
        this.$log.error('Error',e);
        this.$helper.showMessage('Formulario de usuario','Ocurrió un error al cargar el formulario, recargue la página e intente de nuevo, si el problema persiste favor de reportarlo al área de IT.', 'error', 'alert', 20000)
      }
    }

    ,cerrar_modal(){
      this.formulario = false
      this.modalEliminar = false

      this.usuario = {
        id: null,
        usuario: null,
        nombre: null,
        email: null,
        roles_usuario: []
      }
    }

    ,async guardar(){

      this.validacion_seleccion_roles = !this.usuario.roles_usuario.length
      let isValid = await this.$validator({
        nombre_usuario: !this.usuario.nombre ? 'required' : 'min:5|max:255',
        sexo_usuario: !this.usuario.sexo ? 'required' : '',
        rfc_usuario: !this.usuario.rfc ? 'required' : 'min:12|max:13',
        curp_usuario: !this.usuario.curp ? 'required' : 'size:18',
        email_usuario: !this.usuario.email ? 'required' : 'max:255',
      })

      if (isValid && !this.validacion_seleccion_modulos) {

        if (!this.usuario.id) {
          await apiClientes.crear_usuario(this.aplicacion_id, this.cliente_id, this.usuario)
          this.cerrar_modal()
          await this.cargar_usuarios()

        } else {
          await apiClientes.editar_usuario(this.aplicacion_id, this.cliente_id, this.usuario.id, this.usuario).then(() => {
            this.$helper.showMessage('Editar usuario', 'Usuario actualizado exitosamente.', 'success', 'alert')
            this.cerrar_modal()
            this.cargar_usuarios()
          })
        }
      }
    }

    ,pre_eliminar(item){
      this.usuario  = item
      this.modalEliminar = true
    }

    ,async eliminar_usuario(){
      await apiClientes.eliminar_usuario(this.aplicacion_id, this.cliente_id, this.usuario.id).then(() => {
        this.$helper.showMessage('Eliminar usuario','Usuario eliminado exitosamente.', 'success', 'alert')
        this.cerrar_modal()
        this.cargar_usuarios()
      })
    }

    ,async reset_password(){
      await apiClientes.reset_password(this.aplicacion_id, this.cliente_id, this.usuario.id).then(() => {
        this.$helper.showMessage('Generar nueva contraseña','Nueva contraseña se generó correctamente.', 'success', 'alert')
        this.cerrar_modal()
        this.cargar_usuarios()
      })
      this.modalResetPassword = false
    }

  }
  ,watch:{
    "usuario.rfc": {
      handler(value){
        if (!!value){
          this.usuario.rfc =   value.toUpperCase()
        }
      }
    }
    ,"usuario.curp": {
      handler(value){
        if (!!value){
          this.usuario.curp =   value.toUpperCase()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
input.checkbox {
  display: inline-block;
}
.tab-pane{
  padding-top: 25px;
}

#lista-modulos-paquete{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

#lista-modulos-form{
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}
</style>
