import appsApi from "./admin.api";
import utils from "@/helpers/utilidades";

export default {
    /**
     *
     * @param aplicacion
     * @returns {Promise<AxiosResponse<any>>}
     */
    index(aplicacion) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicacion/${aplicacion}/paquetes`));
    },

    crear(aplicacion,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicacion/${aplicacion}/paquetes`, payload)
    },

    editar(aplicacion, paquete, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicacion/${aplicacion}/paquetes/${paquete}`, payload)
    },

    eliminar(aplicacion, paquete) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicacion/${aplicacion}/paquetes/${paquete}`)
    },

}
