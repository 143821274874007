import appsApi from "./admin.api";
import utils from "@/helpers/utilidades";

export default {
    /**
     * Consulta y filtra la lista de aplicaciones
     * @param options
     * @param paginado
     * @returns {Promise<AxiosResponse<any>>}
     */
    index(options, paginado = false) {
        return appsApi.api.get(utils.url_options(appsApi.baseURL+'/aplicaciones', options, paginado));
    },

    buscar(busqueda){
        let options = {
            filters: [{
                "relacion": "and",
                "columna": "nombre",
                "operador": "contains",
                "valor1": busqueda,
                "valor2": null
            }],
            limit: 30,
            order_col: "id",
            order_dir: "desc",
        }
        let url = utils.url_options(`${appsApi.baseURL}/aplicaciones/buscar`, options)
        return appsApi.api.get(url);
    },

    crear(payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicaciones`, payload)
    },

    editar(aplicacion, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicaciones/${aplicacion}`, payload)
    },

    eliminar(aplicacion) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicaciones/${aplicacion}`)
    },

    editar_estatus(aplicacion, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicaciones/${aplicacion}/estatus`, payload)
    },

    roles(aplicacion){
        return appsApi.api.get(`${appsApi.baseURL}/aplicaciones/${aplicacion}/roles`)
    }
}
